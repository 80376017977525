import React, { useState, useEffect } from 'react'
import './EthernetCaf.css'
import logo from './images/logo.jpg'
import mail from './images/mail.jpg'
import phone from './images/phone.jpg'
import web from './images/web.jpg'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import { useAuthContext } from '../../../../../../context/auth.context'
import { useLoadRomsFormFieldListData } from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { encode } from '../../cafCrf.util'

function EthernetCaf({ formData, queueData, romsActivity, submittedForms }) {
  const [cafData, setCafData] = useState(false)
  const [ethernetDataUpdated, setUpdatedCafData] = useState(null)
  const [execuSignImageData, setexecuSignImageData] = useState(null)
  const [execuSignImageType, setexecuSignImageType] = useState(null)
  const [subscImageData, setsubscImageData] = useState(null)
  const [subscImageType, setsubscImageType] = useState(null)
  const [attachPhotoData, setattachPhotoData] = useState(null)
  const [attachPhotoType, setattachPhotoType] = useState(null)

  const chakraTheme = useTheme()

  const {
    state: { authData },
  } = useAuthContext()

  const { mutate } = useLoadRomsFormFieldListData()

  useEffect(() => {
    let data = ethernetDataUpdated
    if (!!data) {
      if (!!data[321476]?.value) {
        let acctManagerSignUrl = data[321476]?.value
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setsubscImageData(data)
          setsubscImageType(type)
        })
      }
      if (!!data[321459]?.value) {
        let photographUrl = data[321459]?.value
        loadImageBlob(photographUrl, (data, type) => {
          setexecuSignImageData(data)
          setexecuSignImageType(type)
        })
      }
      if (!!data[332820]?.value) {
        let attachphotoUrl = data[332820]?.value
        loadImageBlob(attachphotoUrl, (data, type) => {
          setattachPhotoData(data)
          setattachPhotoType(type)
        })
      }
    }
  }, [ethernetDataUpdated])

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data
          setCafData(false)
          setUpdatedCafData(finalFields)
        },
        onError: async err => {
          console.log('error occurred while loading fields', err)
        },
      }
    )
  }
  useEffect(() => {
    setCafData(true)
    fetchFieldListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let etCafContentView = ''
  if (!!ethernetDataUpdated) {
    etCafContentView = (
      <div>
        <page size="A4">
          <div className="section2">
            <div className="font8">
              <div className="rowcol">
                <div className="onet">
                  <img src={logo} alt="img" />
                  <div className="rowcol">
                    <div className="oneh intext">
                      <p>
                        <img src={phone} alt="img" /> {` 1800 266 4986`}
                      </p>
                      <p>
                        <img src={web} alt="img" /> {` www.expl.in`}
                      </p>
                      <p>
                        <img src={mail} alt="img" /> {` info@expl.in`}
                      </p>
                    </div>
                    <div className="oneh">
                      <div className="tx1">CUSTOMER APPLICATION FORM</div>
                      <div className="tx2">
                        (Please fill the form in block letters only)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="oneo">
                  <div className="onerow">
                    {attachPhotoData !== null ? (
                      <img
                        style={{ height: '90px' }}
                        src={
                          attachPhotoData !== null
                            ? 'data:' +
                              attachPhotoType +
                              ';base64,' +
                              encode(attachPhotoData)
                            : ''
                        }
                        alt={'Attach Photograph'}
                      />
                    ) : (
                      <p>
                        Affix PP size <br />
                        photograph here
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="bx1">
                <div className="order">Order Reference Number:</div>
                <div className="st1">
                  <div className="st1text">
                    1. Name of the Customer / Entity
                  </div>
                  <div className="stbox rowcol">
                    <input
                      className="form_col"
                      type="text"
                      name
                      value={ethernetDataUpdated[320634]?.value}
                      readOnly
                    />
                    <input
                      className="form_col"
                      type="text"
                      name
                      value={ethernetDataUpdated[321464]?.value}
                      readOnly
                    />
                    <input
                      className="form_col"
                      type="text"
                      name
                      value={ethernetDataUpdated[321465]?.value}
                      readOnly
                    />
                  </div>
                  <div className="rowcol">
                    <div className="nethre">First Name</div>
                    <div className="nethre">Middle Name</div>
                    <div className="nethre">Surname</div>
                  </div>
                </div>
                <div className="st1">
                  <div className="st1text">2. Father’s / Husband Name</div>
                  <div className="stbox">
                    <input
                      className="form_col"
                      type="text"
                      name
                      value={ethernetDataUpdated[320635]?.value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="rowcol pb10">
                  <div className="nethre flex">
                    Date of Birth{' '}
                    <div className="inbox">
                      {' '}
                      <input
                        className="form_col"
                        type="text"
                        name
                        value={ethernetDataUpdated[330807]?.value}
                        readOnly
                      />{' '}
                    </div>
                  </div>
                  <div className="nethre flex">
                    Nationality{' '}
                    <div className="inbox">
                      {' '}
                      <input
                        className="form_col"
                        type="text"
                        name
                        value={ethernetDataUpdated[330808]?.value}
                        readOnly
                      />{' '}
                    </div>
                  </div>
                  <div className="nethre flex">
                    Sex{' '}
                    <table className="stb">
                      <tbody>
                        <tr>
                          <td>
                            {' '}
                            <input
                              type="text"
                              placeholder="M"
                              value={
                                ethernetDataUpdated[320640]?.value === 'Male'
                                  ? ethernetDataUpdated[320640]?.value
                                  : ''
                              }
                              readOnly
                            />{' '}
                          </td>
                          <td>
                            {' '}
                            <input
                              type="text"
                              placeholder="F"
                              value={
                                ethernetDataUpdated[320640]?.value === 'Female'
                                  ? ethernetDataUpdated[320640]?.value
                                  : ''
                              }
                              readOnly
                            />{' '}
                          </td>{' '}
                          <td>
                            <input
                              type="text"
                              placeholder="Others"
                              value={
                                ethernetDataUpdated[320640]?.value === 'Other'
                                  ? ethernetDataUpdated[320640]?.value
                                  : ''
                              }
                              readOnly
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="st1">
                  <div className="st1text">
                    3. Address (with proper landmark)
                  </div>
                  <div className="stbox">
                    <input
                      className="form_col"
                      type="text"
                      name
                      value={ethernetDataUpdated[320650]?.value}
                      readOnly
                    />
                  </div>
                  <div className="stbox">
                    <input
                      className="form_col"
                      type="text"
                      name
                      value={''}
                      readOnly
                    />
                  </div>
                  <div className="rowcol">
                    <div className="nethre"></div>
                    <div className="nethre"></div>
                    <div className="nethre flex1">
                      Pin Code{' '}
                      <div className="inbox">
                        {' '}
                        <input
                          className="form_col"
                          type="text"
                          name
                          value={ethernetDataUpdated[320645]?.value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="st1">
                  <div className="st1text">
                    4. From where did you hear about us?
                  </div>
                  <div className="check margin0 over_hid">
                    <div className="fleft pad-r20">
                      Social Media
                      <input
                        className="form_col"
                        type="checkbox"
                        name
                        defaultChecked={[
                          'Whatsapp',
                          'Facebook',
                          'Instagram',
                        ].includes(ethernetDataUpdated[320646]?.value)}
                        readOnly
                      />
                    </div>
                    <div className="fleft pad-r20">
                      Website
                      <input
                        className="form_col"
                        type="checkbox"
                        name
                        defaultChecked={['Website'].includes(
                          ethernetDataUpdated[320646]?.value
                        )}
                        readOnly
                      />
                    </div>
                    <div className="fleft pad-r20">
                      Google
                      <input
                        className="form_col"
                        type="checkbox"
                        name
                        defaultChecked={['Google'].includes(
                          ethernetDataUpdated[320646]?.value
                        )}
                        readOnly
                      />
                    </div>
                    <div className="fleft pad-r20">
                      Referred by a Friend/Relative
                      <input
                        className="form_col"
                        type="checkbox"
                        name
                        defaultChecked={['Email'].includes(
                          ethernetDataUpdated[320646]?.value
                        )}
                        readOnly
                      />
                    </div>
                    <div className="fleft pad-r20">
                      Banner
                      <input
                        className="form_col"
                        type="checkbox"
                        name
                        defaultChecked={['Banner'].includes(
                          ethernetDataUpdated[320646]?.value
                        )}
                        readOnly
                      />
                    </div>
                    <div className="fleft pad-r20">
                      Radio
                      <input
                        className="form_col"
                        type="checkbox"
                        name
                        defaultChecked={['Radio'].includes(
                          ethernetDataUpdated[320646]?.value
                        )}
                        readOnly
                      />
                    </div>
                    <div className="fleft pad-r20">
                      TV
                      <input
                        className="form_col"
                        type="checkbox"
                        name
                        defaultChecked={['IVR'].includes(
                          ethernetDataUpdated[320646]?.value
                        )}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="over_hid auth">
                  <div className="fleft width50">
                    Others:
                    <input
                      className="form_col width59"
                      type="text"
                      name
                      value={ethernetDataUpdated[320657]?.value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid auth">
                  <div className="padb10">
                    Kindly mention User ID / contact no. of the person who
                    referred you to us:
                    <input
                      className="form_col width30"
                      type="text"
                      name
                      value={ethernetDataUpdated[320658]?.value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="st1">
                  <div className="st1text">
                    5. . Document proof (Attach photo copies of address proof
                    and photo ID)
                  </div>
                  <div className="ptb7">
                    <div className="auth margin0 over_hid padl20">
                      <div className="fleft width50">
                        a. Address proof: Adhar Card No.:
                        <input
                          className="form_col width50"
                          type="text"
                          name
                          value={ethernetDataUpdated[320659]?.value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width50">
                        Driving License No.:
                        <input
                          className="form_col width60"
                          type="text"
                          name
                          value={ethernetDataUpdated[320660]?.value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid padl30">
                    <div className="fleft width30">
                      Passport No.
                      <input
                        className="form_col width50"
                        type="text"
                        name
                        value={ethernetDataUpdated[320661]?.value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Pan Card No.:
                      <input
                        className="form_col width50"
                        type="text"
                        name
                        value={''}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Elect. Bill No.:
                      <input
                        className="form_col width60"
                        type="text"
                        name
                        value={ethernetDataUpdated[320663]?.value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="ptb7">
                    <div className="auth margin0 over_hid padl20">
                      <div className="fleft width50">
                        b. Photo ID: Adhar Card No.:
                        <input
                          className="form_col width50"
                          type="text"
                          name
                          value={ethernetDataUpdated[321857]?.value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width50">
                        Driving License No.:
                        <input
                          className="form_col width60"
                          type="text"
                          name
                          value={ethernetDataUpdated[321861]?.value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="auth margin0 over_hid padl30">
                    <div className="fleft width30">
                      Passport No.
                      <input
                        className="form_col width50"
                        type="text"
                        name
                        value={ethernetDataUpdated[321859]?.value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Pan Card No.:
                      <input
                        className="form_col width50"
                        type="text"
                        name
                        value={ethernetDataUpdated[320662]?.value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width40">
                      Elect. Bill No.:
                      <input
                        className="form_col width60"
                        type="text"
                        name
                        value={''}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="st1">
                  <div className="st1text">6. Contact Details:</div>
                  <div className="rowcol pb10 padl30">
                    <div className="nethre flex">
                      Primary:{' '}
                      <div className="inbox">
                        {ethernetDataUpdated[321442]?.value}
                      </div>
                    </div>
                    <div className="nethre flex">
                      Alternate No.:{' '}
                      <div className="inbox">
                        {ethernetDataUpdated[321443]?.value}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check margin0 over_hid">
                  <div className="fleft pad-r20">
                    Broadband
                    <input
                      className="form_col"
                      type="checkbox"
                      name
                      defaultChecked={
                        ethernetDataUpdated[321444]?.value === 'Broadband'
                          ? true
                          : false
                      }
                      readOnly
                    />
                  </div>
                  <div className="fleft pad-r20">
                    SME
                    <input
                      className="form_col"
                      type="checkbox"
                      name
                      defaultChecked={
                        ethernetDataUpdated[321444]?.value === 'SME'
                          ? true
                          : false
                      }
                      readOnly
                    />
                  </div>
                  <div className="fleft pad-r20">
                    Corporate
                    <input
                      className="form_col"
                      type="checkbox"
                      name
                      defaultChecked={
                        ethernetDataUpdated[321444]?.value === 'Corporate'
                          ? true
                          : false
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="st1 auth">
                  <div className="st1text over_hid auth">
                    7. Email ID:{' '}
                    <input
                      className="form_col width50"
                      type="text"
                      name
                      value={ethernetDataUpdated[321445]?.value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="st1 auth">
                  <div className="st1text over_hid auth">
                    <div className="w50 fleft">
                      8. User ID{' '}
                      <input
                        className="form_col width80"
                        type="text"
                        name
                        value={ethernetDataUpdated[321446]?.value}
                        readOnly
                      />
                    </div>
                    <div className="w50 fleft">
                      GST No.{' '}
                      <input
                        className="form_col width80"
                        type="text"
                        name
                        value={ethernetDataUpdated[321447]?.value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="fill">
                  <div className="fhed">TO BE FILLED BY SALES EXECUTIVE</div>
                  <table className="ftable">
                    <tbody>
                      <tr>
                        <td>
                          {' '}
                          <strong> A) Installation </strong> (Cash/ UPI / Cheque
                          / NEFT) {' : '} {ethernetDataUpdated[321448]?.value}
                        </td>
                        <td>₹ {ethernetDataUpdated[334113]?.value}</td>
                        <td>
                          Reference / receipt no.:{' '}
                          {ethernetDataUpdated[321449]?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <strong>B) Plan </strong> (Cash/ UPI / Cheque / NEFT)
                          {' : '}
                          {ethernetDataUpdated[321450]?.value}
                        </td>
                        <td>₹ {ethernetDataUpdated[334114]?.value}</td>
                        <td>
                          Reference / Receipt no:{' '}
                          {ethernetDataUpdated[321451]?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <strong>C) Landline </strong> (Cash/ UPI / Cheque /
                          NEFT){' : '} {ethernetDataUpdated[321452]?.value}
                        </td>
                        <td>₹ {ethernetDataUpdated[334115]?.value}</td>
                        <td>
                          Reference / receipt no.:{' '}
                          {ethernetDataUpdated[321453]?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <strong> D) Modem / Modem cum Router </strong> <br />
                          (Cash/ UPI / Cheque / NEFT){' : '}
                          {ethernetDataUpdated[321454]?.value}
                        </td>
                        <td>₹ </td>
                        <td>
                          Reference / receipt no.:{' '}
                          {ethernetDataUpdated[321455]?.value}
                        </td>
                      </tr>
                      <tr className="bg1">
                        <td className="text-center">
                          {' '}
                          <strong>Total</strong>{' '}
                        </td>
                        <td>₹ {ethernetDataUpdated[321456]?.value}</td>
                        <td>PAID (A + B + C + D)</td>
                      </tr>
                      <tr className="blubg">
                        {' '}
                        <td /> <td /> <td />{' '}
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          {' '}
                          <strong>Estimated Date of Installation:</strong>{' '}
                          {ethernetDataUpdated[321457]?.value}
                        </td>
                        <td>
                          {' '}
                          <strong>Date of Payment:</strong>{' '}
                          {ethernetDataUpdated[321458]?.value}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          {' '}
                          {execuSignImageData !== null && (
                            <img
                              style={{ height: '90px' }}
                              src={
                                execuSignImageData !== null
                                  ? 'data:' +
                                    execuSignImageType +
                                    ';base64,' +
                                    encode(execuSignImageData)
                                  : ''
                              }
                              alt={'Signature of the Executive'}
                            />
                          )}
                          <br />{' '}
                          <strong>Name &amp; Signature of the Executive</strong>{' '}
                        </td>
                        <td>
                          {' '}
                          <br />
                          <strong>Date</strong>{' '}
                          {ethernetDataUpdated[321460]?.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  I understand that this payment is being collected for and on
                  behalf of the Business Associate mentioned above. I have read
                  and hereby agree to the Terms and Conditions of ETHERNET
                  XPRESS.
                </p>
                <div className="over_hid auth">
                  <div className="fleft width50">
                    {subscImageData !== null && (
                      <img
                        style={{ height: '90px' }}
                        src={
                          subscImageData !== null
                            ? 'data:' +
                              subscImageType +
                              ';base64,' +
                              encode(subscImageData)
                            : ''
                        }
                        alt={'Signature of the Subscriber'}
                      />
                    )}{' '}
                    <br /> <strong> Signature of Subscriber</strong>
                  </div>
                  <div className="fleft width50">
                    <br /> <strong>Date</strong> &nbsp;
                    {ethernetDataUpdated[321477]?.value}
                  </div>
                </div>
                <div className="hbrnch">
                  Head office: Near Mango Tree Rest. Beach Rd, Vagator, Goa –
                  403509. <br />
                  Branch Office: Bldg-S2, Office No. 201, Nova Cidade Complex
                  2nd Floor, Porvorim, Bardez, Goa – 403521.
                  <br />
                  Branch Office (Margao): Shop #A - 312, 3rd floor Block-A,
                  Reliance Trade Centre, Varde Valaulikar Road, Salcete, Margao,
                  Goa.
                  <br />
                  Branch Office (Vasco): Shop No. 8, Anand Chambers, FI Gomes
                  Rd, New Vaddem, Vasco da Gama, Goa 403802.
                  <br />
                  <div className="text-center">
                    {' '}
                    For the latest Terms and Conditions, Plans and Policies
                    visit: www.expl.in
                  </div>
                </div>
              </div>
            </div>
          </div>
        </page>
        <page size="A4">
          <div className="section2">
            <div className="tertext">TERMS &amp; CONDITIONS</div>
            <div className="font8">
              <div className="gentxt"> GENERAL: </div>
              <ul className="ulist">
                <li>
                  {' '}
                  Subject to the acceptance of the application and technical
                  feasibility Ethernet Xpress India Pvt. Ltd. (EXPL) will
                  endeavour to provide the Broadband service at the earliest.{' '}
                </li>
                <li>
                  {' '}
                  In order to subscribe to EXPL one must be 18 (eighteen) years
                  of age or older and must provide valid documents as requested.{' '}
                </li>
                <li>
                  {' '}
                  Devices and Installation Charges are NON refundable / NON
                  transferable. Installation charges includes: Activation Fee +
                  Installation cost Fee.
                </li>
                <li>
                  {' '}
                  Plan amount is NON refundable / NON transferable to any other
                  account.{' '}
                </li>
                <li>
                  {' '}
                  EXPL does not hold responsibility for lesser Download / Upload
                  data rates caused by the accessed website status, the
                  international gateway or the media.{' '}
                </li>
                <li>
                  {' '}
                  The Residential Broadband connection is likely to be withdrawn
                  in case the customer surrenders the Broadband connection.{' '}
                </li>
                <li>
                  {' '}
                  If a connection is taken during an offer period and devices
                  are provided free of cost, then the devices will be the sole
                  property of EXPL &amp; the device must be returned back at the
                  time of termination of service in working condition. Fiber
                  cable used for a connection remains as the sole property of
                  EXPL.{' '}
                </li>
                <li>
                  {' '}
                  Modem / Modem + Router comes with 1 year warranty from date of
                  installation no matter how many replacements are made during
                  the warranty period.{' '}
                </li>
                <li>
                  {' '}
                  Warranty will be treated null &amp; void if product is
                  physically damaged, water damaged, burnt, broken by natural
                  disaster, or use which is different from the EXPL advice and
                  instructions.{' '}
                </li>
                <li>
                  {' '}
                  EXPL will only be able to replace defective product with a
                  same model or an equivalent product. No option of upgrading to
                  or substituting different products. The maximum liability of
                  EXPL is equal to, and no higher than the products purchased
                  price.{' '}
                </li>
                <li>
                  {' '}
                  Please retain the invoice or receipt to verify the purchase
                  for any warranty claim. EXPL may decline to offer warranty
                  service if the effective invoice or receipt is failed to be
                  produced.
                </li>
                <li>
                  {' '}
                  Power Supply, LAN, WiFi Router, Extenders and internal
                  fittings of cables and wiring to be taken care by the
                  customer.{' '}
                </li>
                <li>
                  {' '}
                  EXPL will provide service till our devices (Landline handset,
                  Modem / Modem + WiFi Router) whichever are mentioned in
                  application form and will NOT be responsible for any
                  additional purchase (LAN, Routers, Extender, etc.) from EXPL
                  employees / third party.{' '}
                </li>
                <li>
                  {' '}
                  Shifting of a Residential Broadband connection is subjected to
                  the technical feasibility. If feasible, shifting of connection
                  will initiate after the payment of shifting charges. If
                  existing device (Landline, Modem / Modem + WiFi Router)
                  doesn’t support at place of shifting, then, device replacement
                  charges are applicable.{' '}
                </li>
                <li>
                  {' '}
                  If devices are provided free of cost and does NOT support at
                  place of shifting, the devices must be returned back in
                  working condition with the power adapter.
                </li>
                <li>
                  {' '}
                  Conditions for providing Home / Business Plan. <br />
                  a. A Residential Broadband plan can only be offered to
                  subscribers working at homes / at residence for personal
                  purpose. <br />
                  b. Subscribers having connection in the name of company firms,
                  shops, education institutes or any other commercial entity can
                  be given only Corporate Plans. They are not eligible for
                  Residential Broadband plans. <br />
                  c. Connection working in individual names at commercial
                  business/office premises are also not entitled for Home plans.
                </li>
                <li>
                  {' '}
                  EXPL shall endeavour to provide reliable &amp; efficient
                  services to its customer.{' '}
                </li>
                <li>
                  {' '}
                  EXPL may update its Internet services, provide new facilities,
                  modify &amp; delete certain features, upgrade its network
                  capacity, bandwidth, speed, add new nodes &amp; reconfigure
                  its network as per need without prior intimation.{' '}
                </li>
                <li>
                  {' '}
                  EXPL may revise the tariff for its Residential Broadband
                  Services &amp; its Value-Added Services from time to time at
                  its own discretion.{' '}
                </li>
                <li>
                  {' '}
                  Technician charges will be applicable if the users face issues
                  with third party devices and wants a technician visits their
                  residence.{' '}
                </li>
                <li>
                  {' '}
                  Reconnection of services – account will be suspended if NOT
                  recharged within 3 months. Reconnection charges are
                  applicable.{' '}
                </li>
                <li>
                  {' '}
                  If payment done via Cheque, connection will be processed once
                  cheque is cleared. If Cheque bounced, return charges of
                  Rs.350/- will be applicable.{' '}
                </li>
                <li>
                  {' '}
                  If customers want to activate their future plan, the existing
                  plan data or valid days will not be carried forward the future
                  plan.{' '}
                </li>
              </ul>
              <div className="gentxt">RIGHT TO TERMINATE SERVICE:</div>
              <ul className="ulist">
                <li>
                  {' '}
                  EXPL reserves the right to disconnect the service to any
                  customer in case there is sufficient evidence of the customer
                  intentionally or unintentionally using the service in a manner
                  which would adversely impact EXPL.{' '}
                </li>
                <li>
                  {' '}
                  The customer shall be responsible for using the service only
                  for legal and appropriate purposes.{' '}
                </li>
                <li>
                  {' '}
                  EXPL reserves the right to terminate the services in the event
                  of non-payment of bills preferred by EXPL in accordance with
                  the tariff plans opted by the customer and the extent of usage
                  or any default on the part of customers.
                </li>
              </ul>
              <div className="gentxt">
                THE CUSTOMER UNDERSTANDS AND ACKNOWLEDGMENT THAT:{' '}
              </div>
              <ul className="ulist">
                <li>
                  Mode of connectivity will be Fiber optic which is over head,
                  EXPL gives no guarantee of cable cut. Reporting of a cable cut
                  shall be attended and resolved at the earliest.{' '}
                </li>
                <li>
                  {' '}
                  Necessary Installation charges is payable to EXPL for
                  connecting Modem/ONU another Interface equipment etc. as
                  applicable and the same is nonrefundable / non-transferable.
                  The customer is required to complete necessary formalities at
                  EXPL office.{' '}
                </li>
                <li>
                  {' '}
                  Forgery of any identification or obscuring of hostnames,
                  usernames, IP addresses or any message header information in
                  any data is not permitted.{' '}
                </li>
                <li>
                  {' '}
                  Sending harassing or threatening transmission over EXPL leased
                  Internet service is not permitted.{' '}
                </li>
                <li>
                  {' '}
                  Internet contains unedited materials some of which may be
                  sexually explicit or offensive &amp; provocative for which
                  EXPL has no control.{' '}
                </li>
                <li>
                  {' '}
                  Messages or communications, which are inconsistent with the
                  established laws of the country cannot be created, downloaded
                  or accessed.{' '}
                </li>
                <li>
                  {' '}
                  Customer acknowledges the Terms and Conditions of the offers
                  &amp; policies which are mentioned on the website.{' '}
                </li>
                <li>
                  {' '}
                  If subscribed to OTT services through EXPL, the customer
                  acknowledges the Terms &amp; Conditions mentioned on the
                  website (www.expl.in).{' '}
                </li>
                <li>
                  {' '}
                  Customer accepts total responsibility and risk for use of the
                  Internet Services and is in compliance with all the applicable
                  laws including but not limited to Information Technology Act,
                  2000 and the Rules made thereunder.
                </li>
              </ul>
              <div className="over_hid auth">
                {' '}
                <div className="fleft width70"> &nbsp; </div>
                <div className="fleft width30 text-center ">
                  {subscImageData !== null && (
                    <img
                      style={{ height: '90px' }}
                      src={
                        subscImageData !== null
                          ? 'data:' +
                            subscImageType +
                            ';base64,' +
                            encode(subscImageData)
                          : ''
                      }
                      alt={'Signature of the Subscriber'}
                    />
                  )}{' '}
                  <br /> <strong> Signature of Subscriber</strong>
                </div>
              </div>
            </div>
          </div>
        </page>
      </div>
    )
  } else {
    etCafContentView = cafData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        Ethernet CAF Form not submitted yet.
      </div>
    )
  }
  return <div>{etCafContentView}</div>
}

export default EthernetCaf
