import React, { useState, useEffect } from 'react'
import logo from './images/logo.png'
import sign from './images/sign.png'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import { useAuthContext } from '../../../../../../context/auth.context'
import { useLoadRomsFormFieldListData } from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { encode } from '../../cafCrf.util'
import './InvoiceCafForm.css'
import {
	formatInvoiceMMMDDYYYY,
	formatInvoicePODate,
} from '../../../../../../utils/date.utils'
import { currencyFormat } from '../../../../../../utils/common.util'

function InvoiceCafForm({ formData, queueData, romsActivity, submittedForms }) {
	const [cafData, setCafData] = useState(false)
	const [invoiceDataUpdated, setUpdatedCafData] = useState(null)
	const [subscImageData, setsubscImageData] = useState(null)
	const [subscImageType, setsubscImageType] = useState(null)

	const chakraTheme = useTheme()

	const {
		state: { authData },
	} = useAuthContext()

	const { mutate } = useLoadRomsFormFieldListData()

	useEffect(() => {
		let data = invoiceDataUpdated
		if (!!data) {
			if (!!data[333988]?.value) {
				let acctManagerSignUrl = data[333988]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setsubscImageData(data)
					setsubscImageType(type)
				})
			}
		}
	}, [invoiceDataUpdated])

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setCafData(false)
					setUpdatedCafData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	useEffect(() => {
		setCafData(true)
		fetchFieldListData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	let etCafContentView = ''
	if (!!invoiceDataUpdated) {
		etCafContentView = (
			<div className='container'>
				<div className='row'>
					<div className='col-md-7' style={{ paddingTop: 120 }}>
						<p>GRENE ROBOTICS (INDIA) PRIVATE LIMITED</p>
						<br/>
						<p>
							Plot No. 437, Road No.20, <br />
							Jubilee Hills,
							<br />
							Hyderabad - 500 033
							<br />
							CIN:U45200TG2006PTC049030
						</p>
						<br/>
						<p>
							Contact Person : {invoiceDataUpdated[333934]?.value},{' '}
							{invoiceDataUpdated[333935]?.value}
						</p>
						<br/>
						<p>Bill To/Ship To</p>
						<br/>
						<p>
							{invoiceDataUpdated[333006]?.value} <br />
							{invoiceDataUpdated[333007]?.value} <br />
							PAN:{invoiceDataUpdated[333008]?.value} GSTIN:
							{invoiceDataUpdated[333009]?.value}
						</p>
					</div>
					<div className='col-md-5'>
						<img src={logo} alt='logo' style={{ marginBottom: 30 }} />
						<table className='tbl1'>
							<tbody>
								<tr>
									<td>Invoice #</td>
									<td>
										<strong> {invoiceDataUpdated[333014]?.value} </strong>
									</td>
								</tr>
								<tr>
									<td>Invoice Date</td>
									<td>
										<strong>
											{formatInvoiceMMMDDYYYY(
												invoiceDataUpdated[333015]?.value
											)}
										</strong>
									</td>
								</tr>
								<tr>
									<td>Client Name</td>
									<td>
										<strong>{invoiceDataUpdated[333016]?.value}</strong>
									</td>
								</tr>
								<tr>
									<td>Client GSTIN</td>
									<td>
										<strong>{invoiceDataUpdated[333017]?.value}</strong>
									</td>
								</tr>
								<tr>
									<td>Place of Supply</td>
									<td>
										<strong>{invoiceDataUpdated[333018]?.value}</strong>
									</td>
								</tr>
								<tr>
									<td>Country of Supply</td>
									<td>
										<strong>{invoiceDataUpdated[333019]?.value}</strong>
									</td>
								</tr>
								<tr>
									<td>Period</td>
									<td>
										<strong>{invoiceDataUpdated[333020]?.value}</strong>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className='table tb1'>
					<table>
						<tbody>
							<tr className='ponumber'>
								<th>PO Number</th>
								<th>{invoiceDataUpdated[333010]?.value}</th>
								<th>PO Dated</th>
								<th>
									{formatInvoicePODate(invoiceDataUpdated[333011]?.value)}
								</th>
								<th>GRN#</th>
								<th>{invoiceDataUpdated[333012]?.value}</th>
								<th>Item No. {invoiceDataUpdated[333013]?.value}</th>
							</tr>
							<tr>
								<th colSpan={3}>DESCRIPTION</th>
								<th>SAC Code</th>
								<th>QTY</th>
								<th>RATE INR</th>
								<th>AMOUNT INR</th>
							</tr>
							<tr>
								<td colSpan={3}>{invoiceDataUpdated[333021]?.value}</td>
								<td>{invoiceDataUpdated[333022]?.value}</td>
								<td>{invoiceDataUpdated[333023]?.value}</td>
								<td>
									{currencyFormat.format(
										invoiceDataUpdated[333024]?.value || 0
									)}
								</td>
								<td>
									{currencyFormat.format(
										invoiceDataUpdated[333025]?.value || 0
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={3}>{invoiceDataUpdated[333981]?.value}</td>
								<td>{invoiceDataUpdated[333982]?.value}</td>
								<td>{invoiceDataUpdated[333983]?.value}</td>
								<td>
									{currencyFormat.format(
										invoiceDataUpdated[333984]?.value || 0
									)}
								</td>
								<td>
									{currencyFormat.format(
										invoiceDataUpdated[333985]?.value || 0
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={5} />
								<td>TOTAL</td>
								<td>
									{currencyFormat.format(
										invoiceDataUpdated[333986]?.value || 0
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={5} />
								<td>TAXABLE VALUE</td>
								<td>
									{currencyFormat.format(	
										invoiceDataUpdated[333986]?.value || 0
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={4} />
								<td>IGST @</td>
								<td>{invoiceDataUpdated[333026]?.value}%</td>
								<td>
									{currencyFormat.format(
										invoiceDataUpdated[333027]?.value || 0
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={5} />
								<td>
									<strong> TOTAL INVOICE VALUE </strong>
								</td>
								<td>
									<strong>
										{currencyFormat.format(
											invoiceDataUpdated[333028]?.value || 0
										)}
									</strong>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<p>
							Total Invoice Value (In Words):{' '}
							{invoiceDataUpdated[333029]?.value} <br />
							<br />
							Grene Robotics Company Details:
							<br />
							PAN - AAKCS1013J
							<br />
							DD/Cheques/NEFT/RTGS in favor of "M/s GRENE ROBOTICS (INDIA)
							PRIVATE LIMITED"
							<br />
							Bank Details:
							<br />
							Beneficiary Name: GRENE ROBOTICS (INDIA) PRIVATE LIMITED
							<br />
							Beneficiary Bank: AXIS BANK
							<br />
							Beneficiary A/c : 921020030631991
							<br />
							IFSC Code : UTIB0000515
							<br />
							GSTIN - 36AAKCS1013J1ZH
						</p>
					</div>
					<div className='col-md-6 text-center' style={{ paddingTop: 70 }}>
						{subscImageData !== null && (
							<img
								style={{ height: '90px' }}
								src={
									subscImageData !== null
										? 'data:' +
										  subscImageType +
										  ';base64,' +
										  encode(subscImageData)
										: ''
								}
								alt={'Signature'}
							/>
						)}
					</div>
				</div>
				<div className='text-center line20 ftr'>
					Plot number 437, Road number 20 Jubilee Hills, Hyderabad-500033,
					Telangana, India <br />
					Website: www.grenerobotics.com, Email Id: Contact@grenerobotics.com
					<br />
					CIN:U45200TG2006PTC049030 Phone No: +91 7680090003
				</div>
			</div>
		)
	} else {
		etCafContentView = cafData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				Invoice CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{etCafContentView}</div>
}

export default InvoiceCafForm
